import * as Gen from '@concordia/super-json-api-client'
import {
  AllBrokersHistory,
  BrokerHistoryData,
  InterestRateHistory,
  SBroker,
  SPointsTotals,
  SPortfolio,
  SUserStrategy,
  UserHistoryData,
  UtilizationHistory,
  transformEvents
} from './types'

export class Fetcher {
  client: Gen.SuperClient

  constructor(jsonApiUrl: string) {
    this.client = new Gen.SuperClient({
      BASE: `${jsonApiUrl}`
    })
  }

  async fetchToggles(): Promise<Gen.FeatureToggles> {
    return await this.client.default.getToggles()
  }

  async fetchPortfolioWithRisk(address: string): Promise<SPortfolio | null> {
    try {
      const portfolio = await this.client.default.getPortfolio(address)
      if (!portfolio) {
        console.log('Portfolio not found')
        return null
      }
      const nextCollaterals = portfolio.collaterals.map((c) => {
        return {
          ...c,
          scaledAmount: Number(c.scaledAmount)
        }
      })
      const nextLiabilities = portfolio.liabilities.map((l) => {
        return {
          ...l,
          scaledAmount: Number(l.scaledAmount)
        }
      })
      return {
        ...portfolio,
        collaterals: nextCollaterals,
        liabilities: nextLiabilities
      }
    } catch (e: any) {
      return null
    }
  }

  async getUserVault(
    address: string,
    baseToken: string,
    multiplyToken: string
  ): Promise<SUserStrategy | null> {
    try {
      const userStrategy = await this.client.default.getUserVault(address, baseToken, multiplyToken)
      return userStrategy
    } catch (e: any) {
      return null
    }
  }

  async fetchSimulatedPortfolio(portfolio: Gen.CoreTypes_PortfolioState): Promise<Gen.Evaluation> {
    return await this.client.default.getRiskSimulated(portfolio)
  }

  async fetchBrokers(): Promise<SBroker[]> {
    try {
      const brokers = await this.client.default.getBrokers()
      return brokers.map((broker: Gen.Broker) => ({
        ...broker,
        scaledAvailableLiquidityUnderlying: Number(broker.scaledAvailableLiquidityUnderlying),
        scaledTotalBorrowedUnderlying: Number(broker.scaledTotalBorrowedUnderlying),
        interestFeeRate: Number(broker.interestFeeRate)
      }))
    } catch (e: any) {
      console.error(e)
      return []
    }
  }

  async fetchWalletBalances(address: string): Promise<Gen.WalletBalances> {
    return await this.client.default.getBalances(address)
  }

  async fetchHistoricalPrices(instrumentNames: string[]): Promise<Gen.CoreTypes_HistoricalPrices> {
    const historicalPrices = await this.client.default.getPriceHistoryForYear(instrumentNames)
    return historicalPrices
  }

  async fetchUserBalancesHistory(
    address: string
  ): Promise<Gen.CoreTypes_PortfolioHistoryByDateTotals> {
    const balanceHistoryResponse = await this.client.default.getPortfolioTransactionsHistoryTotals(
      address
    )
    return balanceHistoryResponse
  }

  async fetchPointsTotals(): Promise<SPointsTotals> {
    const totals = await this.client.default.getLatestPointsHistoryTotals()
    //check each object in totals and check if address is 65 characters long
    //if it is that long, add a 0 as the 3rd character
    //this is a workaround for a leading zero missing in data
    for (let i = 0; i < totals.length; i++) {
      if (totals[i].address.length === 65) {
        totals[i].address = '0x0' + totals[i].address.slice(2)
      }
    }

    return totals
  }

  async fetchReferralsCode(address: string): Promise<string> {
    const code = await this.client.default.getReferalCode(address)
    if (!code) {
      return ''
    }
    return code.code
  }

  async fetchReferralsByUser(address: string): Promise<Gen.CoreTypes_ReferralsByUser> {
    const referrals = await this.client.default.getReferralsByUser(address)
    return referrals
  }

  async fetchStrategies(): Promise<Gen.CoreTypes_FlashLoanStrategy[]> {
    const strategies = await this.client.default.getStrategies()
    return strategies
  }

  async fetchBrokerHistories(
    brokerNames: string[],
    userAddress: string
  ): Promise<AllBrokersHistory> {
    const args = {
      from: '2021-01-01',
      to: '2025-01-01',
      size: 1000,
      offset: 0
    }
    const userArgs = {
      from: '2021-01-01',
      to: '2025-01-01',
      size: 1000,
      offset: 0
    }

    const brokerHistories = await this.client.default.getTransactions(
      brokerNames,
      args.from,
      args.to,
      args.offset,
      args.size
    )

    let userHistories = {}
    const hasUserAddress = userAddress && userAddress.length > 0 && userAddress != undefined

    if (hasUserAddress) {
      let usethisAddress = userAddress

      if (userAddress[2] === '0') {
        usethisAddress = '0x' + userAddress.slice(3)
      }

      userHistories = await this.client.default.getTransactionsForUser(
        usethisAddress,
        brokerNames,
        userArgs.from,
        userArgs.to,
        userArgs.offset,
        userArgs.size
      )
    }

    const hasUserHistories = userHistories && Object.keys(userHistories).length > 0

    if (!brokerHistories && !hasUserHistories) {
      return {
        brokers: {},
        user: {}
      }
    }

    if (!hasUserHistories) {
      const allBrokers: AllBrokersHistory = transformBrokerData(brokerHistories, null)
      return allBrokers
    }

    const allHistory: AllBrokersHistory = transformBrokerData(brokerHistories, userHistories)
    return allHistory
  }

  async refreshBrokerHistories(
    brokerNames: string[],
    userAddress: string
  ): Promise<AllBrokersHistory> {
    const args = {
      from: '2021-01-01',
      to: '2025-01-01',
      size: 1000,
      offset: 0
    }
    const userArgs = {
      from: '2021-01-01',
      to: '2025-01-01',
      size: 1000,
      offset: 0
    }

    const brokerHistories = await this.client.default.getTransactions(
      brokerNames,
      args.from,
      args.to,
      args.offset,
      args.size
    )

    let userHistories = {}
    const hasUserAddress = userAddress && userAddress.length > 0

    let usethisAddress = userAddress

    if (userAddress[2] === '0') {
      usethisAddress = '0x' + userAddress.slice(3)
    }

    if (hasUserAddress) {
      userHistories = await this.client.default.getTransactionsForUser(
        usethisAddress,
        brokerNames,
        userArgs.from,
        userArgs.to,
        userArgs.offset,
        userArgs.size
      )
    }

    const hasUserHistories = userHistories && Object.keys(userHistories).length > 0

    if (!brokerHistories && !hasUserHistories) {
      return {
        brokers: {},
        user: {}
      }
    }

    if (!hasUserHistories) {
      const allBrokers: AllBrokersHistory = transformBrokerData(brokerHistories, null)
      return allBrokers
    }

    const allHistory: AllBrokersHistory = transformBrokerData(brokerHistories, userHistories)
    return allHistory
  }

  checkValidIp(): Promise<boolean> {
    return this.client.default.checkRegionOk()
  }
}

export const calcLendRate = (borrowRate: number, interestFeeRate: number, utilization: number) => {
  return borrowRate * (1 - interestFeeRate) * utilization
}

function transformBrokerData(
  brokerInput: BrokerHistoryData,
  userInput?: UserHistoryData | null
): AllBrokersHistory {
  const result: AllBrokersHistory = { brokers: {}, user: {} }

  Object.keys(brokerInput).forEach((name) => {
    const brokerData = brokerInput[name]
    const utilizationHistory: UtilizationHistory[] = brokerData.history.map((h) => ({
      timestamp: h.created_at,
      totalLend: parseInt(h.available_liquidity) + parseInt(h.total_borrowed),
      totalBorrow: parseInt(h.total_borrowed),
      util: Number(h.utilization)
    }))

    const INTEREST_FEE_RATE = 0.15

    // Assuming interest rate doesn't change in your provided data structure
    const interestRateHistory: InterestRateHistory[] = brokerData.history.map((h) => {
      const utilization = Number(h.utilization)
      const borrowRate = Number(h.interest_rate)
      const lendRate = calcLendRate(borrowRate, INTEREST_FEE_RATE, utilization)
      return {
        timestamp: h.created_at,
        lendRate,
        borrowRate
      }
    })

    const events = transformEvents(brokerData.events)

    result.brokers[name] = {
      utilizationHistory,
      interestRateHistory,
      events
    }
  })

  if (userInput) {
    Object.keys(userInput).forEach((name) => {
      const userData = userInput[name]
      const events = transformEvents(userData.events)
      result.user[name] = { events }
    })
  }

  return result
}
